// Deneb_project css
.project_v1{
	.project_button{
		margin-bottom: 45px;
		.project_btn{
			background: transparent;
			border: none;
			text-transform: uppercase;
			margin-left: 20px;
			margin-right: 20px;
			color: $title;
			font-weight: 500;
			@media #{$md}{
				margin-left: 10px;
				margin-right: 10px;
			}
			@media #{$xs}{
				margin-bottom: 15px;
			}
			&.active_btn{
				box-shadow: 4px 6.928px 15px 0px rgba(254, 176, 0, 0.4);
				background-image: -moz-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				background-image: -webkit-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				background-image: -ms-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				padding: 8px 25px;
				color: $white;
				border-radius: 30px;
			}
		}
	}
	.projects_slider_content{
		.single_project{
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 10px;
		}
		.slick-dots{
			bottom: -35px;
			li{
				width: 15px;
				height: 5px;
				background: #f2f2f2;
				border-radius: 8px;
				button{
					padding: 0;
					width: 15px;
					height: 5px;
				}
			}
			& .slick-active{
				background: $theme_color;
			}
		}
	}
	.grid_item{
		@media #{$xs}{
			margin-bottom: 40px;
		}
		overflow: hidden;
		background: $white;
		border-radius: 10px;
		@include transition(.5s);
		margin-bottom: 10px;
		&:hover{
			box-shadow: 4px 6.928px 15px 0px rgba(0, 0, 0, 0.15);
			.deneb_info{
				h4{
					a{
						color: $theme_color;
					}
				}
			}
		}
		.deneb_info{
			padding: 30px;
			h4{
				font-weight: 600;
				a{
					color: $title;
				}
			}
		}
	}
}
.project_v2{
	.project_button_2{
		margin-bottom: 30px;
		.project_btn{
			background: transparent;
			border: none;
			text-transform: uppercase;
			margin-left: 20px;
			margin-right: 20px;
			color: $title;
			font-weight: 500;
			@media #{$md}{
				margin-left: 10px;
				margin-right: 10px;
			}
			@media #{$xs}{
				margin-bottom: 15px;
			}
			&.active_btn{
				box-shadow: 4px 6.928px 15px 0px rgba(254, 176, 0, 0.4);
				background-image: -moz-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				background-image: -webkit-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				background-image: -ms-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				padding: 8px 25px;
				color: $white;
				border-radius: 30px;
			}
		}
	}
	.grid_wrapper{
		.grid_item{
			@media #{$xs}{
				margin-bottom: 40px;
			}
			overflow: hidden;
			background: $white;
			border-radius: 10px;
			@include transition(.5s);
			&:hover{
				box-shadow: 4px 6.928px 15px 0px rgba(0, 0, 0, 0.15);
				.deneb_info{
					h4{
						a{
							color: $theme_color;
						}
					}
				}
			}
			.deneb_info{
				padding: 30px;
				h4{
					font-weight: 600;
					a{
						color: $title;
					}
				}
			}
		}
	}
}
.deneb_project{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 30px;
			}
		}
	}
}
// project_v1
.project_v1{
	overflow: hidden;
	position: relative;
	padding-bottom: 120px;
}
// project_v2
.project_v2{
	.grid_item{
		margin-bottom: 30px;
	}
}

.portfolio_area {
    padding-bottom: 110px;
}
@media (max-width: 991px) {
    .portfolio_area {
        padding-bottom: 50px;
    }
}
.portfolio_area .filters ul {
    padding-left: 0;
    margin-bottom: 30px;
}
@media (max-width: 991px) {
    .portfolio_area .filters ul {
        margin-bottom: 20px;
    }
}

.portfolio_area .filters ul li {
    display: inline-block;
    margin-right: 40px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: #05364d;
    cursor: pointer;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.portfolio_area .filters ul li:hover,
.portfolio_area .filters ul li.active {
    color:rgb(254, 176, 0);
}
@media (max-width: 767px) {
    .portfolio_area .filters ul li {
        margin-right: 20px;
    }
}
.single_portfolio {
    position: relative;
    margin-bottom: 30px;
}
@media (max-width: 767px) {
    .single_portfolio {
        margin-bottom: 35px;
    }
}
.single_portfolio .overlay {
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.single_portfolio .short_info {
    position: absolute;
    bottom: 44px;
    left: 80px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.single_portfolio .short_info h4 {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}
.single_portfolio .short_info h4 a {
    color: #ffffff;
}
.single_portfolio .short_info p {
    margin-bottom: 0px;
}
.single_portfolio:hover .overlay {
    top: 20px;
    left: 20px;
    background: rgb(254, 176, 0);
}
.single_portfolio:hover .short_info {
    bottom: 24px;
    left: 60px;
    opacity: 1;
    visibility: visible;
}
.portfolio_details_inner .row {
    margin-bottom: 35px;
}
@media (max-width: 480px) {
    .portfolio_details_inner .row {
        margin-bottom: 30px;
    }
}
.portfolio_details_inner p {
    margin-bottom: 30px;
    font-size: 14px;
}
.portfolio_details_inner p:last-child {
    margin-bottom: 0px;
}
.portfolio_details_inner .portfolio_right_text {
    padding: 30px;
    background: #f8faff;
    margin-top: 10px;
}
.portfolio_details_inner .portfolio_right_text h4 {
    font-size: 20px;
    text-transform: capitalize !important;
    margin-bottom: 10px;
}
.portfolio_details_inner .portfolio_right_text p {
    margin-bottom: 25px;
    font-size: 14px;
}
.portfolio_details_inner .portfolio_right_text .list li {
    margin-bottom: 7px;
    font-weight: normal;
    font-size: 14px;
}
.portfolio_details_inner .portfolio_right_text .list li span {
    font-family: "Roboto", sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 97px;
    display: inline-block;
}
.portfolio_details_inner .portfolio_right_text .list li:last-child {
    margin-bottom: 0px;
}
.portfolio_details_inner .portfolio_right_text .list li i {
    color: #f1cd09;
}
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.portfolio-three{
	h6{
		text-transform: uppercase;
		color: #feb000;
	}
	h3{
		font-size: 36px;
	}
}