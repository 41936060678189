// deneb_banner
.deneb_banner{
	position: relative;
	padding-top: 120px;
	padding-bottom: 10px;
	.hero_slider{
		.signle_slider{
			.banner_content{
				@media #{$md}{
					margin-bottom: 60px;
				}
				@media #{$xs}{
					margin-bottom: 60px;
				}
				h1{
					font-weight: 500;
					margin-left: -2px;
					margin-bottom: 10px;
					@media #{$xs}{
						font-size: 30px;
					}
					span{
						font-weight: bold;
						color: $theme_color;
					}
				}
				h5{
					font-weight: 500;
					margin-bottom: 15px;
				}
				p{
					margin-bottom: 36px;
				}
			}
			.deneb_img_box{
				position: relative;
				float: right;
				margin-right: 18px;
			}
		}
		.slick-dots{
			bottom: -50px;
			li{
				width: 5px;
				height: 5px;
				background: #c3c3c3;
				border-radius: 50%;
				button{
					padding: 0;
					width: 5px;
					height: 5px;
					border-radius: 50%;
				}
			}
			& .slick-active{
				background: $theme_color;
				button{
					&:before{
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background-color: rgb(255, 189, 39);
						opacity: 0.302;
						margin-top: -5px;
					    margin-left: -5px;
					    position: absolute;
					    top: 50%;
					    left: 50%;
					}
				}
			}
		}
	}
}
// shape_v1
.shape_v1{
	.shape_1{
		position: absolute;
		left: 0;
		top: 80px;
	}
}
// .shape_v2
.shape_v2{
	.shape_7{
		position: absolute;
		right: 0;
		top: 100px;
	}
}
.shape_v3{
	.shape_10{
		position: absolute;
		left: 0;
		top: 60px;
	}
}
.shape_v4{
	.shape_11{
		position: absolute;
		right: 0;
		top: 0px;
	}
}
.shape_v5{
	.shape_12{
		position: absolute;
		left: 0;
		top: 0px;
	}
	.shape_13{
		position: absolute;
		left: 40px;
		bottom: 60px;
	}
}

.banner_two{
	min-height: 100vh;
	background-image: url(../images/banner_two.jpg);
	background-position: center;
	background-size: cover;
	position: relative;
	.row{
		min-height: 100vh;
		
	}
	.content{
		position: relative;
		z-index: 3;
		h1{
			color: #fff;
			margin-bottom: 20px;
			span{
				color: #feb000;
			}
		}
		h6{
			color: #feb000;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
		.video-popup{
			background-color: #fff;
			color: #000;
			min-width: 45px;
			min-height: 45px;
			font-size: 17px;
			padding: 0px 0px 0px 3px;
			border-radius: 50%;
			line-height: 45px;
			animation: glow 1s infinite alternate;
			@keyframes glow {
				from {
				  box-shadow: 0 0 3px -3px #aef4af;
				}
				to {
				  box-shadow: 0 0 3px 3px #aef4af;
				}
			  }
		}
	}
}
.banner_two::after{
	background: #000;
	content: "";
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 2;
	opacity: 0.6;
}

.hero-three {
	background: url(../images/wave.svg);
	background-position: top;
	background-repeat: no-repeat;
	.row{
	min-height: 100vh;
		span{
			color: #5DBBFF;
		}
		p{
			font-size: 20px;
			line-height: 28px;
		}
		.deneb_btn{
			height: inherit;
			width: inherit;
			line-height: inherit;
			padding: 15px 40px;
			border-radius: 40px;
		}
	}
	@media #{$md}{
		.row{
			min-height: 55vh;
			h2{
				font-size: 32px !important;
			}
			p{
				font-size: 16px;
			}
		}
	}
	@media #{$sm}{
		padding-top: 30px;
		background-size: 2000px 400px;
		.row{
			min-height: 70vh !important;
			padding: 100px 0px 50px;
			img{
				margin-top: 40px;
			}
			h2{
				font-size: 32px;
			}
			p{
				font-size: 18px;
			}
		}
	}
}