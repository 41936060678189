// deneb_testimonial
.deneb_testimonial{
	overflow: hidden;
	padding-bottom: 165px;
	.row1{
		max-width: 700px;
		display: flex;
		justify-content: space-between;
		margin: auto;
		margin-bottom: 110px;
		.single_img{
			border-radius: 50%;
			opacity: .7;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.3);
			img{
				opacity: .7;
			}
			&:nth-child(2){
				margin-top: -25px;
			}
		}
		.sm_img{
			border-radius: 50%;
			width: 88px;
			height: 88px;
		}
	}
	.row2{
		display: flex;
		justify-content: space-between;
		.single_img{
			border-radius: 50%;
			opacity: .7;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.3);
			img{
				border-radius: 50%;
				opacity: .7;
			}
		}
		.sm_img{
			border-radius: 50%;
			width: 88px;
			height: 88px;
		}
	}
	.testimonial_slide{
		max-width: 730px;
		text-align: center;
		margin: auto;
		margin-top: -200px;
		.single_testimonial{
			padding-top: 20px;
			.client_img{
				max-width: 200px;
				max-height: 200px;
				position: relative;
				margin: auto;
				margin-bottom: 30px;
				border-radius: 50%;
				box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
				z-index: 1;
				&:after{
					content: '';
					width: 200px;
					height: 200px;
					position: absolute;
					top: -10px;
					right: -10px;
					border-radius: 50%;
					background-image: -moz-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
					background-image: -webkit-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
					background-image: -ms-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
					z-index: -1;
				}
				img{
					margin: 0 auto;
					border-radius: 50%;
					max-width: 200px;
					max-height: 200px;
				}
				.qoute{
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					border-radius: 50%;
					color: $white;
					font-size: 18px;
					background-image: -moz-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
					background-image: -webkit-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
					background-image: -ms-linear-gradient( 180deg, rgb(254,176,0) 0%, rgb(255,188,38) 100%);
				}
			}
			.client_info{
				h3{
					font-weight: bold; 
				}
				h5{
					font-size: 18px;
					color: #ffbb23;
					font-weight: 400;
				}
				p{
					padding-top: 20px;
				}
			}
		}
		.slick-dots{
			bottom: -50px;
			li{
				width: 15px;
				height: 5px;
				background: #c3c3c3;
				border-radius: 8px;
				button{
					padding: 0;
					width: 15px;
					height: 5px;
				}
			}
			& .slick-active{
				background: $theme_color;
			}
		}
	}
}
.deneb_testimonial{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 50px;
			}
		}
	}
}
// testimonial_v1
.testimonial_v1{
	position: relative;
	
}

/* Start Testimonial Area css
============================================================================================ */
.testimonial_area {
    padding-bottom: 100px;
}
@media (min-width: 600px) {
    .testimonial_area {
        padding-bottom: 180px;
    }
}
@media (min-width: 1200px) {
    .testimonial_area {
        padding-bottom: 200px;
    }
}
.testimonial-slider {
    position: relative;
    z-index: 1;
}
.testimonial-slider::after {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 20px;
    width: 465px;
    height: 384px;
    transform: scale(0.8);
    background: url(../images/testimonials/testimonial-bg.png) left center no-repeat;
    background-size: cover;
    z-index: -1;
    display: none;
}
@media (min-width: 992px) {
    .testimonial-slider::after {
        display: block;
    }
}
@media (min-width: 1200px) {
    .testimonial-slider::after {
        width: 465px;
        height: 384px;
        transform: scale(1);
    }
}
.testimonial-slider .testimonial-item {
    margin-top: 30px;
}
@media (min-width: 992px) {
    .testimonial-slider .testimonial-item {
        margin-top: 50px;
    }
}
.testimonial-slider .owl-item img {
    width: 250px;
    height: 238px;
    margin-right: auto;
    margin-left: auto;
}
.testimonial-slider h4 {
    font-size: 20px;
    margin-bottom: 4px;
}
.testimonial-slider small {
    font-size: 14px;
    font-weight: 300;
}
.testimonial-slider p {
    color: #5b6d75;
}
.testimonial-slider .owl-dots {
    position: absolute;
    left: 50%;
    padding-top: 20px;
    transform: translateX(-50%);
}
@media (min-width: 992px) {
    .testimonial-slider .owl-dots {
        left: 70%;
    }
}
.testimonial-slider .owl-dots .owl-dot span {
    background: #c8cfda;
}
.testimonial-slider .owl-dots .owl-dot.active span {
    background: #5f30ff;
}
.star_rating {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.star_rating li {
    display: inline-block;
    margin-right: 6px;
}
.star_rating li:last-child {
    margin-right: 0;
}
.star_rating li i,
.star_rating li span {
    color: #ffd804;
    font-size: 15px;
}

.star_rating li.disable i,
.star_rating li.disable span {
    color: #aeb9be;
}
.testimonial-three{
	h6{
		text-transform: uppercase;
		color: #feb000;
	}
	h3{
		font-size: 36px;
	}
	.testimonial{
		text-align: center;
		padding: 85px 50px 45px 70px;
		margin: 70px 15px 35px;
		background: #fbfbfe;
		box-shadow: 5px 4px 0 0 #5DBBFF;
		position: relative;
		.pic{
			width: 120px;
			height: 120px;
			margin: 0 auto;
			position: absolute;
			top: -60px;
			left: 0;
			right: 0;
			img{
				width: 100%;
				height: auto;
			}
		}
		.description{
			font-size: 15px;
			color: #757575;
			line-height: 27px;
			margin-bottom: 20px;
			position: relative;
		}
		.testimonial-profile{
			position: relative;
			margin: 20px 0 10px 0;
		}
		.title{
			display: inline-block;
			font-size: 18px;
			color: #4a5184;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 0;
		}
		.post{
			display: inline-block;
			font-size: 15px;
			color: #757575;
			text-transform: capitalize;
		}
	}
}
.testimonial .owl-dots{
    margin-top: 10px;
}
.testimonial .owl-carousel .owl-dot span{
    background: #5e5f5f;
    opacity: 1;
    transition: all 0.4s ease 0s;
}
.testimonial .owl-carousel .owl-dot.active span,
.testimonial .owl-carousel .owl-dot:hover span{
    background: #5DBBFF;
}
.testimonial .owl-carousel .owl-dot.active span{
    width: 22px;
    height: 12px;
}
.clients img{
	margin: 10px 0px;
}
@media #{$sm}{
	.client{
		width: 50%;
		max-width: 50%;
	}
}